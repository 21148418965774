<template>
  <div>
    <h3
      class="text-2xl text-dokBlue-ultra font-semibold border-0 border-b-2 border-solid pb-2 mb-4"
    >
      <i class="fas fa-pills"></i> Traitements chroniques
    </h3>

    <div class="flex flex-col w-full">
      <div class="grid gap-2 grid-cols-2 items-center">
        <div>
          <label>Actuellement, prenez-vous des médicaments ? </label> <br />
          <p class="text-gray-500 text-lg">Si oui lesquels et pourquoi ?</p>
        </div>
        <!-- <toggle-button v-model="showMedicamentsBlock" :sync="true" :labels="{checked: 'On', unchecked: 'Off'}"/> -->
        <div class="flex justify-end mb-5">
          <p class="text-xs text-gray-500">
            (Choisissez une maladie de la liste et saisissez le lien parental
            puis cliquer sur <i class="fa fa-plus"></i> pour sauvegarder)
          </p>
        </div>
      </div>
      <div
        class="flex justify-center lg:justify-end w-full ml-auto mt-3"
        v-if="showMedicamentsBlock"
      >
        <table class="table-auto border-0 border-collapse w-full">
          <thead>
            <tr>
              <th
                class="double-line px-4 py-4 bg-dokBlue-ultra text-white border-r border-solid  align-middle text-center "
              >
                Nom du Médicament/complément
              </th>
              <th
                class="px-4 py-2 bg-dokBlue-ultra text-white text-center  border-b border-solid"
                colspan="2"
              >
                Indiquez le dosage quotidien
              </th>
              <th
                class="double-line px-4 py-4 bg-dokBlue-ultra text-white border-r border-solid align-middle border-l  text-center"
              >
                Pour quelle pathologie?
              </th>
            </tr>
          </thead>
          <tbody>
            <medicament
              :patient="patient"
              v-for="(medic, indx) in listMedicaments"
              :key="indx"
              :medicament="medic"
              v-on:medicamentSaved="updateListe()"
              v-on:medicamentRemoved="removeFromList"
            >
            </medicament>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const toggleInput = () => import("@/views/global-components/toggle");
const Medicament = () => import("./medicament");
import { remove } from "lodash";
export default {
  props: ["patient"],
  components: { toggleInput, Medicament },
  data() {
    return {
      listMedicaments: [],
      showMedicamentsBlock: true
    };
  },
  async mounted() {
    var el = this;
    this.$store.dispatch("healthbook/GET_MEDICAMENTS_LIST", {
      patientId: this.patient._id,
      onData: this.onData
    });
  },
  watch: {
    showMedicamentsBlock: function(newVal) {
      const el = this;
      if (
        !newVal &&
        this.listMedicaments.length > 1 &&
        this.listMedicaments[0].name
      ) {
        var vonf = confirm(
          "En désactivant vous risquer de tout supprimer. \nVoulez-vous vraiement confirmer cette action ?"
        );
        if (vonf) {
          this.$store.dispatch("healthbook/REMOVE_ALL_PATIENT_MEDICAMENTS", {
            patientId: this.patient._id,
            onData: function({ ok }) {
              if (ok) {
                el.listMedicaments = [
                  { name: "", moreInfo: null, frequence: null }
                ];
              }
              el.$forceUpdate();
            }
          });
        } else {
          setTimeout(function() {
            el.showMedicamentsBlock = true;
          }, 200);
        }
      }
    }
  },
  methods: {
    onData(response) {
      this.listMedicaments = response.data;
      // this.showMedicamentsBlock = this.listMedicaments && this.listMedicaments.length > 0;
      this.listMedicaments.push({ name: "", moreInfo: null, frequence: null });
      this.$forceUpdate();
    },
    saveAllMedicaments() {},
    removeFromList(medic) {
      if (this.listMedicaments) {
        remove(this.listMedicaments, function(o) {
          return o._id && o._id == medic._id;
        });
        if (this.listMedicaments.length == 0) {
          this.listMedicaments.push({
            name: "",
            moreInfo: null,
            frequence: null
          });
        }
        this.$forceUpdate();
      }
    },
    updateListe() {
      if (this.listMedicaments) {
        this.listMedicaments.push({
          name: "",
          moreInfo: null,
          frequence: null
        });
      }
    }
  }
};
</script>

<style></style>
